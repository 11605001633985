<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> ဌာန အကောင့် ထည့်ရန်</h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label">ဌာန <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<v-select class="style-chooser" placeholder="ရွေးချယ်မည်"
									v-model="accountRequest.department_id"  :reduce="(option) => option.id" label="name" :options="departmentList" @keydown="validationRule()"/>
									<small class="text-danger">{{validationData.department_id}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputPosition" class="col-sm-2 col-form-label">ရာထူး <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<select class="form-control" id="inputPosition" v-model="accountRequest.position" @change="validationRule()">
										<option value="">ရွေးချယ်မည်</option>
										<option value="hod">Head Of Department</option>
										<option value="staff">Staff</option>
									</select>
									<small class="text-danger">{{validationData.position}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label">နာမည် <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="accountRequest.name" @blur="validationRule()"
										class="form-control" id="inputName">
									<small class="text-danger">{{validationData.name}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputemail" class="col-sm-2 col-form-label">အီးမေးလ် <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="accountRequest.email" @blur="validationRule()"
										class="form-control" id="inputemail">
									<small class="text-danger">{{validationData.email}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputPassword" class="col-sm-2 col-form-label">လျှို့ဝှက်စကား <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="password" v-model="accountRequest.password" @blur="validationRule()"
										class="form-control" id="inputPassword">
									<small class="text-danger">{{validationData.password}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputConfirmPassword" class="col-sm-2 col-form-label">လျှို့ဝှက်စကား နောက်တစ်ကြိမ် <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="password" v-model="accountRequest.conPassword" @blur="validationRule()"
										class="form-control" id="inputConfirmPassword">
									<small class="text-danger">{{validationData.conPassword}}</small>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">လုပ်ဆောင်မည်</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'dAccountCreate',
		metaInfo: {
            title: "Department Account Create",
            titleTemplate: "%s ← SC Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				departmentList: [],
				accountRequest: {
					department_id: "",
					position: "",
					name: "",
					email: "",
					password: "",
					conPassword: "",
					image: ""
				},
				validationData: {
					department_id: "",
					position: "",
					name: "",
					email: "",
					password: "",
					conPassword: "",
					image: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				dAccountStoreAction: 'dAccountStoreAction',
				dAccountFieldsAction: 'dAccountFieldsAction'
			}),
			async getFields(){
				let option = {
					fields: {type: 'store'}
				}
				await this.dAccountFieldsAction({
					...option
				}).then(res => {
					this.departmentList = res.data.department
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = this.accountRequest
				await this.dAccountStoreAction({
					...option
				}).then(res => {
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'department-account-list', query: {page: 1, reverse: 'desc', sort: 'created_at', name: '', email: '', department_id: ''} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				if (this.accountRequest.department_id == "") {
					this.validationData.department_id = "The department field is required."
					isValidation.push(true)
				}else if (this.accountRequest.department_id == null) {
					this.validationData.department_id = "The department field is required."
					isValidation.push(true)
				} else {
					this.validationData.department_id = ""
					isValidation.push(false)
				}
				if (this.accountRequest.position == "") {
					this.validationData.position = "The position field is required."
					isValidation.push(true)
				} else {
					this.validationData.position = ""
					isValidation.push(false)
				}
				if (this.accountRequest.name == "") {
					this.validationData.name = "The name field is required."
					isValidation.push(true)
				} else {
					this.validationData.name = ""
					isValidation.push(false)
				}
				if (this.accountRequest.email == "") {
					this.validationData.email = "The email field is required."
					isValidation.push(true)
				} else {
					this.validationData.email = ""
					isValidation.push(false)
				}
				if(this.accountRequest.password == ""){
					this.validationData.password = "The password field is required."
					isValidation.push(true)
				}else{
					this.validationData.password = ""
					isValidation.push(false)
				}
				if(this.accountRequest.conPassword == ""){
					this.validationData.conPassword = "The confirm password field is required."
					isValidation.push(true)
				}else{
					this.validationData.conPassword = ""
					isValidation.push(false)
				}
				if(this.accountRequest.password != this.accountRequest.conPassword){
					this.validationData.conPassword = "The password field does not match!"
					isValidation.push(true)
				}else{
					this.validationData.conPassword = ""
					isValidation.push(false)
				}
                
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>